import React from 'react';
import {sortBy} from 'lodash';
import * as PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import Seo from '../../components/SEO';
import Theme from '../../components/Theme';
import ArticleSection from '../../components/ArticleSection';
import Header from '../../components/Archive/Header';
import CompanyList from '../../components/CompanyList';
import Newsletter from '../../components/Newsletter';
import Pagination from '../../components/Pagination';
import GeneralLayout from '../../layouts/index';

const ThesisTemplate = ({ transition, data, pageContext }) => {
  const { group, index, pageCount, pathPrefix } = pageContext;
  const { contentfulThesis, contentfulNewsletter, site } = data;
  const { name, description, featured, company } = contentfulThesis;

  const companies = sortBy(company, ['name']);

  return (
  <GeneralLayout>
    <Theme theme="blue" transition={transition}>
      <Seo
        siteData={site}
        seoData={contentfulThesis.seo}
        defaultTitle={`Emergence - ${name} Thesis`}
        pathName={`/${name}`}
      />
      <Header overline="Thesis" title={name} description={description} featuredArticle={featured} />
      {group && <ArticleSection articles={group} largeArticle={featured ? true : false} />}
      {pageCount > 1 && <Pagination index={index} pageCount={pageCount} pathPrefix={pathPrefix} />}
      {companies.length > 1 && (
        <CompanyList companies={companies} title="Companies" theme="white" />
      )}
      <Newsletter content={contentfulNewsletter} />
    </Theme>
  </GeneralLayout>
  );
};

ThesisTemplate.propTypes = {
  transition: PropTypes.object,
  data: PropTypes.object.isRequired,
};

export default ThesisTemplate;

export const pageQuery = graphql`
  query thesisQuery($id: String!) {
    contentfulThesis(id: { eq: $id }) {
      ...ThesisArchiveHeaderQuery
      ...ThesisCompaniesQuery
      seo {
        customTitle
        metaDescription
        shareImage {
          file {
            url
          }
        }
      }
    }
    ...NewsletterQuery
    ...SiteQuery
  }
`;
